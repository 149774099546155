(function () {
    window.xhrRequests = []; // Store all XHR instances

    const originalOpen = XMLHttpRequest.prototype.open;
    const originalSend = XMLHttpRequest.prototype.send;

    XMLHttpRequest.prototype.open = function (method, url) {
        this._requestDetails = {
            method,
            url
        }; // Store method and URL
        return originalOpen.apply(this, arguments);
    };

    XMLHttpRequest.prototype.send = function (body) {
        this._requestDetails.body = body;
        window.xhrRequests.push(this); // Store the XHR instance
        return originalSend.apply(this, arguments);
    };

    // Abort all ongoing XHR requests when the page is unloading
    window.addEventListener("beforeunload", function () {
        window.xhrRequests.forEach(xhr => {
            if (xhr.readyState !== 4) { // If request is not completed
                xhr.abort();
            }
        });
    });
})();
